import { useGetHtmlTag } from 'root/hooks/useGetHtmlTag';
import { useStyles } from '@wix/tpa-settings/react';
import stylesParams from 'root/components/Menus/stylesParams';
import { useMenusContext } from 'root/contexts/MenusContext';
import { ChevronDown, ChevronUp, Minus, Plus } from '@wix/wix-ui-icons-common/on-stage';
import { ExpandCollapseIcon } from 'root/consts';
import React, { useState } from 'react';
import { useExperiments } from '@wix/yoshi-flow-editor';

export const useSectionLogic = (isFirstSection?: boolean) => {
  const { isEditor } = useMenusContext();
  const sectionTitleHtmlTag = useGetHtmlTag('sectionTitleTheme');
  const sectionDescriptionHtmlTag = useGetHtmlTag('sectionDescriptionTheme');
  const styles = useStyles();
  const { experiments } = useExperiments();
  const isExpandCollapsePresetExperimentEnabled = experiments.enabled('specs.restaurants.menusOoiExpandCollapsePreset');
  const showSectionDescription = styles.get(stylesParams.showSectionDescription);
  const [sectionCollapsed, setSectionCollapsed] = useState<boolean>(isEditor && isFirstSection ? false : true);
  const expandIcon = styles.get(stylesParams.sectionExpandCollapseIcon);

  return {
    sectionTitleHtmlTag,
    sectionDescriptionHtmlTag,
    sectionCollapsed,
    setSectionCollapsed,
    icons:
      expandIcon === ExpandCollapseIcon.Plus
        ? { expanded: <Minus />, collapsed: <Plus /> }
        : { expanded: <ChevronUp />, collapsed: <ChevronDown /> },
    showSectionDescription,
    isExpandCollapsePresetExperimentEnabled,
  };
};
