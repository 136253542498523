import React from 'react';
import type { PopulatedSection } from 'root/apiTypes';
import { classes } from './Sections.st.css';
import { Section } from 'root/components/Menus/Section/Section';
import { dataHooks } from 'root/dataHooks';
import { Divider } from 'wix-ui-tpa/cssVars';

export const Sections = ({ menuTitleId, sections }: { menuTitleId: string; sections: PopulatedSection[] }) => {
  return (
    <div
      aria-labelledby={menuTitleId}
      className={classes.sectionsContainer}
      data-hook={dataHooks.sections.container}
      role="list"
    >
      {sections.map((section) => (
        <>
          <Divider className={classes.dividerTop} />
          <Section key={section.id} isFirstSection={sections[0].id === section.id} section={section} />
        </>
      ))}
    </div>
  );
};
