import React from 'react';
import type { PopulatedSection } from 'root/apiTypes';
import { st, classes } from './Section.st.css';
import { classes as global } from 'root/components/Menus/Global.st.css';
import { Items } from 'root/components/Menus/Items/Items';
import { Text, TextTypography, Divider, IconButton } from 'wix-ui-tpa/cssVars';
import { dataHooks } from 'root/dataHooks';
import { useSectionLogic } from './Section.logic';
import { SECTION_TITLE_ID } from 'root/consts';

export const Section = ({ section, isFirstSection }: { section: PopulatedSection; isFirstSection?: boolean }) => {
  const {
    sectionTitleHtmlTag,
    sectionDescriptionHtmlTag,
    sectionCollapsed,
    setSectionCollapsed,
    icons,
    isExpandCollapsePresetExperimentEnabled,
  } = useSectionLogic(isFirstSection);
  const sectionTitleId = SECTION_TITLE_ID(section.id!);
  return isExpandCollapsePresetExperimentEnabled ? (
    <div className={classes.sectionContainer} data-hook={dataHooks.section.container} role="listitem">
      <Divider className={st(classes.divider, classes.dividerTop)} />
      <div className={classes.titleContainer} onClick={() => setSectionCollapsed(!sectionCollapsed)}>
        <Text
          className={st(global.fullWidth, classes.sectionTitle)}
          data-hook={dataHooks.section.name}
          id={sectionTitleId}
          tagName={sectionTitleHtmlTag}
          typography={TextTypography.smallTitle}
        >
          {section.name}
        </Text>
        <IconButton
          className={classes.sectionExpandCollapseIcon}
          icon={sectionCollapsed ? icons.collapsed : icons.expanded}
        />
      </div>
      {section.description && (
        <Text
          data-hook={dataHooks.section.description}
          tagName={sectionDescriptionHtmlTag}
          className={st(
            global.fullWidth,
            classes.sectionDescription,
            sectionCollapsed ? classes.collapse : classes.expand
          )}
        >
          {section.description}
        </Text>
      )}
      <Divider className={st(classes.divider, classes.dividerBottom)} />
      <Items
        isSectionCollapsed={sectionCollapsed}
        items={section.items ?? []}
        sectionId={section.id ?? ''}
        sectionTitleId={sectionTitleId}
      />
      <Divider className={st(classes.divider, classes.dividerBottom, classes.expandCollapseDivider)} />
    </div>
  ) : (
    <div className={classes.sectionContainer} data-hook={dataHooks.section.container} role="listitem">
      <Divider className={st(classes.divider, classes.dividerTop)} />
      <Text
        className={st(global.fullWidth, classes.sectionTitle)}
        data-hook={dataHooks.section.name}
        id={sectionTitleId}
        tagName={sectionTitleHtmlTag}
        typography={TextTypography.smallTitle}
      >
        {section.name}
      </Text>
      {section.description && (
        <Text
          className={st(global.fullWidth, classes.sectionDescription)}
          data-hook={dataHooks.section.description}
          tagName={sectionDescriptionHtmlTag}
        >
          {section.description}
        </Text>
      )}
      <Divider className={st(classes.divider, classes.dividerBottom)} />
      <Items items={section.items ?? []} sectionId={section.id ?? ''} sectionTitleId={sectionTitleId} />
    </div>
  );
};
