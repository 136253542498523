// GENERAL
export const MIN_SPACING_RANGE = 0;
export const MAX_SMALL_SPACING_RANGE = 60;
export const MAX_MEDIUM_SPACING_RANGE = 100;
export const MAX_LARGE_SPACING_RANGE = 200;
export const DEFAULT_SMALL_SPACING = 20;
export const DEFAULT_LARGE_SPACING = 40;

export const SMALL_THUMBNAIL_SIZE = 38;
export const MEDIUM_THUMBNAIL_SIZE = 51;
export const LARGE_THUMBNAIL_SIZE = 72;
export const RECTANGLE_THUMBNAIL_WIDTH = 114;
export const RECTANGLE_THUMBNAIL_HEIGHT = 51;
export const MIN_ICON_SIZE = 16;
export const MAX_ICON_SIZE = 60;
export const INPUT_SMALL_WIDTH = '72px';
export const RECT_THUMBNAIL_SIZE = {
  width: 114,
  height: 51,
};

// GRID
export const GRID_MIN_COLUMNS_NUMBER = 2;
export const GRID_MAX_COLUMNS_NUMBER = 6;
export const GRID_MIN_COLUMNS_NUMBER_MOBILE = 1;
export const GRID_MAX_COLUMNS_NUMBER_MOBILE = 2;

// COLUMNS
export const COLUMNS_MIN_COLUMNS_NUMBER = 1;
export const COLUMNS_MAX_COLUMNS_NUMBER = 3;
