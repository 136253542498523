import { useExperiments } from '@wix/yoshi-flow-editor';

export const useItemsLogic = () => {
  const { experiments } = useExperiments();
  const isExpandCollapsePresetExperimentEnabled = experiments.enabled('specs.restaurants.menusOoiExpandCollapsePreset');

  return {
    isExpandCollapsePresetExperimentEnabled,
  };
};
