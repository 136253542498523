import type { ShowcasePopulationLevel } from 'root/apiTypes';
import type { PageData, Range } from './clientTypes';

export const MAX_NUMBER_OF_ENTITIES_FROM_SERVER = 500;
export const MAX_NUMBER_OF_ENTITIES_FROM_SERVER_GET_REQUEST = 75;
export const DEFAULT_APP_WIDTH = 980;

export const SHOWCASE_POPULATION_LEVEL: ShowcasePopulationLevel = {
  labels: true,
  modifierGroups: false,
  modifiers: false,
  priceVariants: true,
};

export const MENU_WIDGET_NAME = 'Menu';
export const PAGE_NAME = 'menus';

export enum MenuGFPPButtons {
  Settings = 'menu settings button',
  Manage = 'manage menus button',
}
export enum MenuPanelName {
  Settings = 'menu settings',
  Manage = 'manage menus',
}

export const APP_DEF_ID = 'b278a256-2757-4f19-9313-c05c783bec92';

export const WIDGET_IDS = {
  menus: '3f7c620e-526a-4dbb-afff-6fb77bfa8dcc',
};

export const PAGE_DATA: PageData = {
  pageId: 'Menus',
  title: 'app.page.title',
  widgetId: WIDGET_IDS.menus,
  pageUriSEO: 'menus-ooi',
};

export enum Alignment {
  Start,
  Center,
  End,
}

export enum AlignmentText {
  Start = 'start',
  Center = 'center',
  End = 'end',
}

export enum Direction {
  Vertical,
  Horizontal,
}

export enum DirectionText {
  Column = 'column',
  Row = 'row',
}

export enum JustifyContent {
  SpaceBetween,
  None,
}

export enum JustifyContentText {
  SpaceBetween = 'space-between',
  None = '',
}

export enum Overflow {
  Wrap,
  Scroll,
}

export enum ExpandCollapseIcon {
  Chevron = 0,
  Plus = 1,
}

export enum HeaderAlignment {
  Right = 'right',
  Left = 'left',
  Center = 'center',
}

export enum ImagePosition {
  Left,
  Right,
}

export enum ImageRatio {
  '16:9' = 16 / 9,
  '4:3' = 4 / 3,
  '1:1' = 1,
  '3:4' = 3 / 4,
  '9:16' = 9 / 16,
}
export const enum ImageSizeCategories {
  SMALL = 0,
  MEDIUM = 1,
  LARGE = 2,
}

export const ImageSize = {
  MOBILE: {
    [ImageSizeCategories.SMALL]: 15,
    [ImageSizeCategories.MEDIUM]: 25,
    [ImageSizeCategories.LARGE]: 40,
  },
  DESKTOP: {
    [ImageSizeCategories.SMALL]: 10,
    [ImageSizeCategories.MEDIUM]: 15,
    [ImageSizeCategories.LARGE]: 25,
  },
};

export const BORDER_INPUT_WIDTH = 170;

export enum MenuListNavigation {
  Tabs = 0,
  Dropdown = 1,
}

export enum FieldName {
  SectionsExpandCollapseIconSize = 'sectionsExpandCollapseIconSize',
  MaxItems = 'maxItemsPerRow',
  ColumnsNumber = 'numberOfColumns',
  ColumnsGap = 'columnsGap',
  SectionGap = 'SectionGap',
  ColumnsItemsGap = 'columnsItemsGap',
  ColumnsItemPadding = 'columnsItemPadding',
  SectionsGap = 'sectionsGap',
  ItemsGap = 'itemsGap',
  SectionPadding = 'sectionPadding',
  SectionPaddingHorizontal = 'sectionPaddingHorizontal',
  SectionPaddingVertical = 'sectionPaddingVertical',
  ItemPadding = 'itemPadding',
  ItemDetailsPadding = 'itemDetailsPadding',
  Default = 'default',
  NavigationLayout = 'navigationLayout',
  NavigationFullWidthTab = 'navigationFullWidthTab',
  TabsPadding = 'tabsPadding',
  TabsSpaceBetween = 'tabsSpaceBetween',
  TabsRowSpaceBetween = 'tabsRowSpaceBetween',
  NavigationAlignment = 'navigationAlignment',
  MenuAlignment = 'menuAlignment',
  SectionAlignment = 'sectionAlignment',
  ItemAlignment = 'itemAlignment',
  menuBackgroundHorizontalPadding = 'menuBackgroundHorizontalPadding',
  menuBackgroundVerticalPadding = 'menuBackgroundVerticalPadding',
  ItemImageBottomPadding = 'itemImageBottomPadding',
  NavigationTabsBackground = 'navigationTabsBackground',
  NavigationTabsArrowsColor = 'navigationTabsArrowsColor',
  NavigationTabsBorderRadius = 'navigationTabsBorderRadius',
  NavigationTabsBorder = 'navigationTabsBorder',
  ItemImagePosition = 'itemImagePosition',
  MenuImagePosition = 'menuImagePosition',
  NavigationTabsText = 'navigationTabsText',
  NavigationTabsIndicator = 'navigationTabsIndicator',
  NavigationTabsDefaultTabFont = 'navigationTabsDefaultTabFont',
  NavigationTabsDefaultTabBackground = 'navigationTabsDefaultTabBackground',
  NavigationTabsDefaultTabBorderRadius = 'navigationTabsDefaultTabBorderRadius',
  NavigationTabsDefaultTabBorder = 'navigationTabsDefaultTabBorder',
  NavigationTabsSelectedTabFont = 'navigationTabsSelectedTabFont',
  NavigationTabsSelectedTabBackground = 'navigationTabsSelectedTabBackground',
  NavigationTabsSelectedTabBorderRadius = 'navigationTabsSelectedTabBorderRadius',
  NavigationTabsSelectedTabBorder = 'navigationTabsSelectedTabBorder',
  NavigationTabsTabBackground = 'navigationTabsTabBackground',
  NavigationTabsTabBorderRadius = 'navigationTabsTabBorderRadius',
  NavigationTabsTabBorder = 'navigationTabsTabBorder',
  NavigationStyle = 'navigationStyle',
  NavigationDropdownText = 'navigationDropdownText',
  NavigationDropdownBackground = 'navigationDropdownBackground',
  NavigationDropdownBorderColor = 'navigationDropdownBorderColor',
  NavigationDropdownBorderWidth = 'navigationDropdownBorderWidth',
  NavigationDropdownBorderRadius = 'navigationDropdownBorderRadius',
  MenusBackgroundColor = 'menusBackgroundColor',
  MenusBorder = 'menusBorder',
  MenusBorderRadius = 'menusBorderRadius',
  MenusTitle = 'menusTitle',
  MenusDescription = 'menusDescription',
  SectionsBackgroundColor = 'sectionsBackgroundColor',
  SectionsBorder = 'sectionsBorder',
  SectionsBorderRadius = 'sectionsBorderRadius',
  SectionsTopDividerWidth = 'sectionsTopDividerWidth',
  SectionsTopDividerColor = 'sectionsTopDividerColor',
  SectionsBottomDividerWidth = 'sectionsBottomDividerWidth',
  SectionsBottomDividerColor = 'sectionsBottomDividerColor',
  SectionsTitleColor = 'sectionsTitleColor',
  SectionsDescriptionColor = 'sectionsDescriptionColor',
  SectionDividerMargin = 'sectionDividerMargin',
  ItemsBackgroundColor = 'itemsBackgroundColor',
  ItemsBorder = 'itemsBorder',
  ItemsBorderRadius = 'itemsBorderRadius',
  ItemsTitle = 'itemsTitle',
  ItemsDescription = 'itemsDescription',
  ItemsPrice = 'itemsPrice',
  ItemsDividerWidth = 'itemsDividerWidth',
  ItemsDividerColor = 'itemsDividerColor',
  ItemsImageRadius = 'itemsImageRadius',
  VariantTitle = 'variantTitle',
  VariantPrice = 'variantPrice',
  VariantDividerColor = 'variantDividerColor',
  DotsBorderColor = 'dotsBorderColor',
  DotsBorderSize = 'dotsBorderSize',
  LabelTitle = 'labelTitle',
  LabelBackgroundColor = 'labelBackgroundColor',
  LabelBorderColor = 'labelBorderColor',
  LabelBorderWidth = 'labelBorderWidth',
  LabelBorderRadius = 'labelBorderRadius',
  UseLabelIconColor = 'useLabelIconColor',
  LabelIconColor = 'labelIconColor',
  LabelIconSize = 'labelIconSize',
  LabelSpaceBetween = 'labelSpaceBetween',
  LabelVerticalPadding = 'labelVerticalPadding',
  LabelHorizontalPadding = 'labelHorizontalPadding',
  LocationsAlignment = 'locationsAlignment',
  LocationsDisplay = 'locationsDisplay',
  ExpandCollapseType = 'expandCollapseType',
}
export const defaultFontSizeRange: Range = { min: 12, max: 100 };

export enum NavigationDropdownStyle {
  Underline,
  Box,
}

export enum MenusDisplay {
  All = 'show all menus',
  Specific = 'show specific menus',
}

export const NAVIGATION_VALUE_MAP: Record<MenuListNavigation, string> = {
  [MenuListNavigation.Tabs]: 'tabs',
  [MenuListNavigation.Dropdown]: 'dropdown',
};

export const ALIGNMENT_VALUE_MAP = {
  [Alignment.Start]: 'start',
  [Alignment.Center]: 'center',
  [Alignment.End]: 'end',
};

export const MENUS_EXTENDED_FIELDS_NAME_SPACE = '@restaurants/menus';

export const MENU_TITLE_ID = (menuId: string) => `${menuId}-menu-title`;
export const SECTION_TITLE_ID = (sectionId: string) => `${sectionId}-section-title`;

export const HASH_TO_PREVENT_SCROLL = '#1';

export const OOI_ARTICLE_ID = 'ecfc3e11-584e-491b-85e9-2799656d287c';
