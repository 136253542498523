import type { ImageSizeCategories } from 'root/consts';
import {
  Direction,
  AlignmentText,
  ImagePosition,
  Alignment,
  ImageSize,
  HeaderAlignment,
  DirectionText,
  JustifyContentText,
} from 'root/consts';
import { getAlignmentByLanguage, getAlignmentText } from 'root/utils/alignment';
import { hasPriceVariantDividers, Preset } from 'root/utils/presets';
import { getIsEndPosition } from './Widget/utils';
import { getDirectionText } from 'root/utils/headerDisplay';

export const getWidgetPadding = ({
  preset,
  gridMenuVerticalPadding,
  gridMenuHorizontalPadding,
  columnsMenuVerticalPadding,
  columnsMenuHorizontalPadding,
  centerMenuVerticalPadding,
  centerMenuHorizontalPadding,
  sideBySideMenuVerticalPadding,
  sideBySideMenuHorizontalPadding,
  expandStripMenuVerticalPadding,
  expandStripMenuHorizontalPadding,
  expandCenterMenuVerticalPadding,
  expandCenterMenuHorizontalPadding,
}: {
  preset: Preset;
  gridMenuVerticalPadding: number;
  gridMenuHorizontalPadding: number;
  columnsMenuVerticalPadding: number;
  columnsMenuHorizontalPadding: number;
  centerMenuVerticalPadding: number;
  centerMenuHorizontalPadding: number;
  sideBySideMenuVerticalPadding: number;
  sideBySideMenuHorizontalPadding: number;
  expandStripMenuVerticalPadding: number;
  expandStripMenuHorizontalPadding: number;
  expandCenterMenuVerticalPadding: number;
  expandCenterMenuHorizontalPadding: number;
}) => {
  switch (preset) {
    case Preset.Grid:
      return `${gridMenuVerticalPadding}px ${gridMenuHorizontalPadding}px`;
    case Preset.Columns:
      return `${columnsMenuVerticalPadding}px ${columnsMenuHorizontalPadding}px`;
    case Preset.Center:
      return `${centerMenuVerticalPadding}px ${centerMenuHorizontalPadding}px`;
    case Preset.SideBySide:
      return `${sideBySideMenuVerticalPadding}px ${sideBySideMenuHorizontalPadding}px`;
    case Preset.ExpandStrip:
      return `${expandStripMenuVerticalPadding}px ${expandStripMenuHorizontalPadding}px`;
    case Preset.ExpandCenter:
      return `${expandCenterMenuVerticalPadding}px ${expandCenterMenuHorizontalPadding}px`;
  }
};

export const getWidgetOverflow = ({ preset, isMobile }: { preset: Preset; isMobile: boolean }) => {
  return preset === Preset.SideBySide && !isMobile ? 'visible' : 'hidden';
};

export const getWidgetDisplay = ({ preset }: { preset: Preset }) => {
  return preset === Preset.SideBySide ? 'grid' : 'block';
};

export const getWidgetGridTemplate = ({
  preset,
  isMobile,
  isRTL,
  imagePosition,
}: {
  preset: Preset;
  isMobile: boolean;
  isRTL: boolean;
  imagePosition: ImagePosition;
}) => {
  if (preset !== Preset.SideBySide) {
    return '';
  }

  const isEndPosition = getIsEndPosition(isRTL, imagePosition, ImagePosition.Left);

  if (isMobile) {
    return `"header"
            "image"
            "menu"
            / 1fr`;
  }

  return isEndPosition
    ? `". image" 20px
       "header image"
       "menu image"
       ". image" auto
       / 1fr 1fr`
    : `"image ." 20px
       "image header"
       "image menu"
       "image ." auto
       / 1fr 1fr`;
};

export const getHeaderContainerPadding = ({ preset, isMobile }: { preset: Preset; isMobile: boolean }) => {
  return preset === Preset.SideBySide && !isMobile ? '0px 40px' : '0px';
};

export const getMenuContentGridArea = ({ preset }: { preset: Preset }) => {
  return preset === Preset.SideBySide ? 'menu' : '';
};

export const getMenuContentPadding = ({ preset, isMobile }: { preset: Preset; isMobile: boolean }) => {
  return preset === Preset.SideBySide && !isMobile ? '0px 40px 20px' : '0px';
};

export const getImageContainerGridArea = ({ preset }: { preset: Preset }) => {
  return preset === Preset.SideBySide ? 'image' : '';
};

export const getImageContainerPosition = ({ preset }: { preset: Preset }) => {
  return preset === Preset.SideBySide ? 'sticky' : '';
};

export const getImageContainerTop = ({ preset }: { preset: Preset }) => {
  return preset === Preset.SideBySide ? 0 : '';
};

export const getImageContainerWidth = ({ preset }: { preset: Preset }) => {
  return preset === Preset.SideBySide ? '100%' : '';
};

export const getImageContainerHeight = ({ preset, isMobile }: { preset: Preset; isMobile: boolean }) => {
  return preset === Preset.SideBySide ? (isMobile ? '20vh' : '100vh') : '';
};

export const getImageContainerPaddingBottom = ({ preset, isMobile }: { preset: Preset; isMobile: boolean }) => {
  return preset === Preset.SideBySide && isMobile ? '40px' : '';
};

export const getNavigationMargin = () => {
  return '40px';
};

export const getMenuMaxWidth = ({ preset }: { preset: Preset }) => {
  return preset === Preset.Center ? '800px' : '';
};

export const getMenuMargin = ({ preset }: { preset: Preset }) => {
  return preset === Preset.Center ? '0 auto' : '';
};

export const getSectionsDisplay = ({ preset }: { preset: Preset }) => {
  switch (preset) {
    case Preset.Grid:
    case Preset.Center:
    case Preset.ExpandCenter:
      return 'grid';
    case Preset.Columns:
    case Preset.ExpandStrip:
    case Preset.SideBySide:
      return 'block';
  }
};

export const getSectionsGridTemplateColumns = ({ preset }: { preset: Preset }) => {
  switch (preset) {
    case Preset.Grid:
    case Preset.Center:
    case Preset.ExpandCenter:
      return '1fr';
    case Preset.Columns:
    case Preset.ExpandStrip:
    case Preset.SideBySide:
      return '';
  }
};

export const getSectionsRowGap = ({
  preset,
  gridGap,
  centerGap,
  expandStripGap,
  expandCenterGap,
}: {
  preset: Preset;
  gridGap: number;
  centerGap: number;
  expandStripGap: number;
  expandCenterGap: number;
}) => {
  switch (preset) {
    case Preset.Grid:
      return `${gridGap}px`;
    case Preset.Center:
      return `${centerGap}px`;
    case Preset.Columns:
    case Preset.SideBySide:
      return '';
    case Preset.ExpandStrip:
      return `${expandStripGap}px`;
    case Preset.ExpandCenter:
      return `${expandCenterGap}px`;
  }
};

export const getSectionsMarginTop = ({
  preset,
  showMenuName,
  showMenuDescription,
}: {
  preset: Preset;
  showMenuName: boolean;
  showMenuDescription: boolean;
}) => {
  const noMenuHeader = !showMenuName && !showMenuDescription;

  return preset === Preset.ExpandCenter || noMenuHeader ? '0px' : '40px';
};

export const getSectionsColumns = ({
  preset,
  isMobile,
  columnsNumOfColumns,
}: {
  preset: Preset;
  isMobile: boolean;
  columnsNumOfColumns: number;
}) => {
  if (preset === Preset.Columns) {
    if (isMobile) {
      return 1;
    }

    return columnsNumOfColumns;
  }

  if (preset === Preset.SideBySide) {
    return 1;
  }

  return '';
};

export const getSectionsColumnsGap = ({ preset, columnsGap }: { preset: Preset; columnsGap: number }) => {
  if (preset === Preset.Columns) {
    return `${columnsGap}px`;
  }

  return '';
};

export const getSectionPadding = ({
  preset,
  gridPadding,
  columnsPadding,
  centerPadding,
  sideBySidePadding,
  expandStripPadding,
  expandCenterPadding,
}: {
  preset: Preset;
  gridPadding: number;
  columnsPadding: number;
  centerPadding: number;
  sideBySidePadding: number;
  expandStripPadding: number[];
  expandCenterPadding: number[];
}) => {
  switch (preset) {
    case Preset.Grid:
      return `${gridPadding}px`;
    case Preset.Columns:
      return `${columnsPadding}px`;
    case Preset.Center:
      return `${centerPadding}px`;
    case Preset.SideBySide:
      return `${sideBySidePadding}px`;
    case Preset.ExpandStrip:
      return `${expandStripPadding[0]}px ${expandStripPadding[1]}px`;
    case Preset.ExpandCenter:
      return `${expandCenterPadding[0]}px ${expandCenterPadding[1]}px`;
  }
};

export const getSectionMarginBottom = ({
  preset,
  columnsMarginBottom,
  sideBySideMarginBottom,
  expandStripMarginBottom,
}: {
  preset: Preset;
  columnsMarginBottom: number;
  sideBySideMarginBottom: number;
  expandStripMarginBottom: number;
}) => {
  switch (preset) {
    case Preset.Columns:
      return `${columnsMarginBottom}px`;
    case Preset.SideBySide:
      return `${sideBySideMarginBottom}px`;
    case Preset.ExpandStrip:
      return `${expandStripMarginBottom}px`;
    default:
      return '';
  }
};

export const getFirstSectionMarginTop = ({ preset }: { preset: Preset }) => {
  return preset === Preset.ExpandCenter ? '40px' : '';
};

export const getSectionTopDividerMarginBottom = ({
  preset,
  expandCenterSectionDividerMargin,
  sectionTopDividerWidth,
}: {
  preset: Preset;
  expandCenterSectionDividerMargin: number;
  sectionTopDividerWidth: number;
}) => {
  if (sectionTopDividerWidth === 0) {
    return '0px';
  }
  switch (preset) {
    case Preset.ExpandCenter:
      return `${expandCenterSectionDividerMargin}px`;
    default:
      return '15px';
  }
};

export const getSectionBottomDividerMarginTop = ({
  sectionBottomDividerWidth,
}: {
  sectionBottomDividerWidth: number;
}) => {
  if (sectionBottomDividerWidth === 0) {
    return '0px';
  }

  return '15px';
};

export const getItemsDisplay = ({ preset }: { preset: Preset }) => {
  switch (preset) {
    case Preset.Grid:
    case Preset.Center:
    case Preset.ExpandCenter:
      return 'grid';
    default:
      return '';
  }
};
export const getItemsGridTemplateColumns = ({
  preset,
  isMobile,
  gridNumOfColumns,
}: {
  preset: Preset;
  isMobile: boolean;
  gridNumOfColumns: number;
}) => {
  switch (preset) {
    case Preset.Grid:
      const gridColumns = isMobile ? Math.min(gridNumOfColumns, 2) : gridNumOfColumns;
      return `repeat(${gridColumns}, 1fr)`;
    case Preset.Center:
    case Preset.ExpandCenter:
      return '1fr';
    default:
      return '';
  }
};

export const getItemsColumnGap = ({ preset, gridItemsColumnGap }: { preset: Preset; gridItemsColumnGap: number }) => {
  if (preset === Preset.Grid) {
    return `${gridItemsColumnGap}px`;
  }

  return '';
};

export const getItemsRowGap = ({
  preset,
  gridItemsRowGap,
  centerItemsRowGap,
  expandCenterItemsRowGap,
}: {
  preset: Preset;
  gridItemsRowGap: number;
  centerItemsRowGap: number;
  expandCenterItemsRowGap: number;
}) => {
  switch (preset) {
    case Preset.Grid:
      return `${gridItemsRowGap}px`;
    case Preset.Center:
      return `${centerItemsRowGap}px`;
    case Preset.ExpandCenter:
      return `${expandCenterItemsRowGap}px`;
    default:
      return '';
  }
};

export const getItemRootMargin = ({
  preset,
  columnsItemsGap,
  sideBySideItemsGap,
  expandStripItemsGap,
}: {
  preset: Preset;
  columnsItemsGap: number;
  sideBySideItemsGap: number;
  expandStripItemsGap: number;
}) => {
  switch (preset) {
    case Preset.Columns:
      return `${columnsItemsGap / 2}px`;
    case Preset.ExpandStrip:
      return `${expandStripItemsGap / 2}px`;
    case Preset.SideBySide:
      return `${sideBySideItemsGap / 2}px`;
    default:
      return '';
  }
};

export const getFirstItemDividerMarginBottom = ({
  showItemDividers,
  itemDividerWidth,
  itemRootMargin,
}: {
  showItemDividers: boolean;
  itemDividerWidth: number;
  itemRootMargin: string;
}) => {
  return !showItemDividers || itemDividerWidth === 0 ? '0' : itemRootMargin;
};

export const getItemGridTemplate = ({
  preset,
  isRTL,
  gridItemDetailsPadding,
  columnsImageWidth,
  expandStripImageWidth,
  centerItemDetailsPadding,
  columnsImagePosition,
  expandStripImagePosition,
  showItemImage,
  showDots,
  isMobile,
}: {
  preset: Preset;
  isRTL: boolean;
  gridItemDetailsPadding: number;
  columnsImageWidth: number;
  expandStripImageWidth: number;
  centerItemDetailsPadding: number;
  columnsImagePosition: number;
  expandStripImagePosition: number;
  showItemImage: boolean;
  showDots: boolean;
  isMobile: boolean;
}) => {
  switch (preset) {
    case Preset.Grid:
      if (showDots) {
        return `[row1-start] "image image image image image" [row1-end]
        [row2-start] ". name dots price ." [row2-end]
        [row3-start] ". description description description ." [row3-end]
        [row4-start] ". labels labels labels ." [row4-end]
        [row5-start] ". price-variants price-variants price-variants ." [row5-end]
        / ${gridItemDetailsPadding}px auto 1fr auto ${gridItemDetailsPadding}px`;
      }

      return `[row1-start] "image image image" [row1-end]
      [row2-start] ". name ." [row2-end]
      [row3-start] ". description ." [row3-end]
      [row4-start] ". labels ." [row4-end]
      [row5-start] ". price ." [row5-end]
      [row5-start] ". price-variants ." [row5-end]
      / ${gridItemDetailsPadding}px 1fr ${gridItemDetailsPadding}px`;
    case Preset.Columns:
      const isEndPosition = getIsEndPosition(isRTL, columnsImagePosition, ImagePosition.Right);

      if (!showItemImage) {
        return `[row1-start] ". . . ." 16px [row1-end]
                [row2-start] "name dots dots price" [row2-end]
                [row3-start] "description description . ." [row3-end]
                [row4-start] "labels labels . ." [row4-end]
                [row5-start] "price-variants price-variants price-variants price-variants" [row5-end]
                / auto 1fr 25% auto`;
      }

      return isEndPosition
        ? `[row1-start] ". . . . . ." 16px [row1-end]
           [row2-start] "image . name dots dots price" minmax(0, max-content) [row2-end]
           [row3-start] "image . description description . ."  minmax(0, max-content) [row3-end]
           [row4-start] "image . labels labels . . "  minmax(0, max-content) [row4-end]
           [row5-start] "image . price-variants price-variants price-variants price-variants"  minmax(0, 1fr) [row5-end]
           / ${columnsImageWidth}% 20px auto 1fr 20px auto`
        : `[row1-start] ". . . . . ." 16px [row1-end]
           [row2-start] "name dots dots price . image" minmax(0, max-content) [row2-end]
           [row3-start] "description description . . . image"  minmax(0, max-content) [row3-end]
           [row4-start] "labels labels . . . image"  minmax(0, max-content) [row4-end]
           [row5-start] "price-variants price-variants price-variants price-variants . image"  minmax(0, 1fr) [row5-end]
           / auto 1fr 20px auto 20px ${columnsImageWidth}%`;
    case Preset.Center:
      return ` [row1-start] ". name ." [row1-end]
               [row2-start] ". description ." [row2-end]
               [row3-start] ". labels ." [row3-end]
               [row4-start] ". price ." [row4-end]
               [row4-start] ". price-variants ." [row4-end]
    / ${centerItemDetailsPadding}px 1fr ${centerItemDetailsPadding}px`;
    case Preset.SideBySide:
      return `[row1-start] ". . . ." 16px [row1-end]
              [row2-start] "name dots dots price" [row2-end]
              [row3-start] "description description . ." [row3-end]
              [row4-start] "labels labels . ." [row4-end]
              [row5-start] "price-variants price-variants price-variants price-variants" [row5-end]
              [row6-start] ". . . ." 4px [row6-end]
              / auto 1fr 25% auto`;
    case Preset.ExpandStrip:
      const isExpandStripImageEndPosition = getIsEndPosition(isRTL, expandStripImagePosition, ImagePosition.Left);
      if (isMobile) {
        if (!showItemImage) {
          return `[row1-start] "." 16px [row1-end]
            [row2-start] "name" [row2-end]
            [row3-start] "description" [row3-end]
            [row4-start] "labels" [row4-end]
            [row5-start] "price-variants" [row5-end]
            [row6-start] "price"[row6-end]
            /  1fr `;
        }
        return isExpandStripImageEndPosition
          ? `[row1-start] ". . . " 16px [row1-end]
             [row2-start] "name  . image" minmax(0, max-content) [row2-end]
             [row3-start] "description  . image"  minmax(0, max-content) [row3-end]
             [row4-start] "labels . image"  minmax(0, max-content) [row4-end]
             [row5-start] "price-variants . image" [row5-end]
              [row6-start] "price . image"  minmax(0, 1fr) [row6-end]
             / 1fr 20px ${expandStripImageWidth}%`
          : `[row1-start] ". . ." 16px [row1-end]
             [row2-start] "image . name" minmax(0, max-content) [row2-end]
             [row3-start] "image . description "  minmax(0, max-content) [row3-end]
             [row4-start] "image . labels"  minmax(0, max-content) [row4-end]
             [row5-start] "image . price-variants"  [row5-end]
             [row6-start] "image . price"  minmax(0, 1fr) [row6-end]
             / ${expandStripImageWidth}% 20px 1fr`;
      }

      if (!showItemImage) {
        return `[row1-start] ". . . ." 16px [row1-end]
                  [row2-start] "name dots dots price" [row2-end]
                  [row3-start] "description description . ." [row3-end]
                  [row4-start] "labels labels . ." [row4-end]
                  [row5-start] "price-variants price-variants price-variants price-variants" [row5-end]
                  / auto 1fr 25% auto`;
      }
      return isExpandStripImageEndPosition
        ? `[row1-start] ". . . . . ." 16px [row1-end]
             [row2-start] "name dots dots price . image" minmax(0, max-content) [row2-end]
             [row3-start] "description description . . . image"  minmax(0, max-content) [row3-end]
             [row4-start] "labels labels . . . image"  minmax(0, max-content) [row4-end]
             [row5-start] "price-variants price-variants price-variants price-variants . image"  minmax(0, 1fr) [row5-end]
             / auto 1fr 20px auto 20px ${expandStripImageWidth}%`
        : `[row1-start] ". . . . . ." 16px [row1-end]
             [row2-start] "image . name dots dots price" minmax(0, max-content) [row2-end]
             [row3-start] "image . description description . ."  minmax(0, max-content) [row3-end]
             [row4-start] "image . labels labels . . "  minmax(0, max-content) [row4-end]
             [row5-start] "image . price-variants price-variants price-variants price-variants"  minmax(0, 1fr) [row5-end]
             / ${expandStripImageWidth}% 20px auto 1fr 20px auto`;

    case Preset.ExpandCenter:
      return ` [row1-start] ". name ." [row1-end]
      [row2-start] ". description ." [row2-end]
      [row3-start] ". labels ." [row3-end]
      [row4-start] ". price ." [row4-end]
      [row4-start] ". price-variants ." [row4-end]
      /20px 1fr 20px`;
  }
};

export const getItemGridTemplateNoImage = ({
  preset,
  isRTL,
  gridItemDetailsPadding,
  columnsImageWidth,
  expandStripImageWidth,
  centerItemDetailsPadding,
  columnsImagePosition,
  expandStripImagePosition,
  showDots,
  isMobile,
}: {
  preset: Preset;
  isRTL: boolean;
  gridItemDetailsPadding: number;
  columnsImageWidth: number;
  expandStripImageWidth: number;
  centerItemDetailsPadding: number;
  columnsImagePosition: number;
  expandStripImagePosition: number;
  showDots: boolean;
  isMobile: boolean;
}) => {
  return getItemGridTemplate({
    preset,
    isRTL,
    gridItemDetailsPadding,
    columnsImageWidth,
    expandStripImageWidth,
    centerItemDetailsPadding,
    columnsImagePosition,
    expandStripImagePosition,
    showItemImage: false,
    showDots,
    isMobile,
  });
};

export const getItemPriceJustifySelf = ({ preset }: { preset: Preset }) => {
  return [Preset.Columns, Preset.SideBySide, Preset.ExpandStrip].includes(preset) ? 'flex-end' : '';
};

export const getItemPriceWidth = ({ preset }: { preset: Preset }) => {
  return preset === Preset.Grid ? 'fit-content' : '100%';
};
export const getItemPriceMarginBottom = ({ preset }: { preset: Preset }) => {
  return [Preset.Center, Preset.ExpandCenter].includes(preset) ? '0px' : '12px';
};

export const getItemImageAspectRatio = ({
  preset,
  gridItemImageRatio,
  columnsItemImageRatio,
  expandStripItemImageRatio,
}: {
  preset: Preset;
  gridItemImageRatio: number;
  columnsItemImageRatio: number;
  expandStripItemImageRatio: number;
}) => {
  switch (preset) {
    case Preset.Grid:
      return gridItemImageRatio;
    case Preset.Columns:
      return columnsItemImageRatio;
    case Preset.ExpandStrip:
      return expandStripItemImageRatio;
    default:
      return '';
  }
};

export const getItemImageMarginBottom = ({
  preset,
  gridItemImageBottomPadding,
}: {
  preset: Preset;
  gridItemImageBottomPadding: number;
}) => {
  switch (preset) {
    case Preset.Grid:
      return `${gridItemImageBottomPadding}px`;
    case Preset.Columns:
    case Preset.ExpandStrip:
      return '12px';
    default:
      return '';
  }
};

export const getItemPadding = ({
  preset,
  gridItemPadding,
  columnsItemPadding,
  sideBySideItemPadding,
  centerItemPadding,
  expandStripItemPadding,
  expandCenterItemPadding,
}: {
  preset: Preset;
  gridItemPadding: number;
  columnsItemPadding: number;
  sideBySideItemPadding: number;
  centerItemPadding: number;
  expandStripItemPadding: number;
  expandCenterItemPadding: number;
}) => {
  switch (preset) {
    case Preset.Grid:
      return `${gridItemPadding}px`;
    case Preset.Columns:
      return `${columnsItemPadding}px`;
    case Preset.Center:
      return `${centerItemPadding}px`;
    case Preset.SideBySide:
      return `${sideBySideItemPadding}px`;
    case Preset.ExpandStrip:
      return `${expandStripItemPadding}px`;
    case Preset.ExpandCenter:
      return `${expandCenterItemPadding}px`;
  }
};

export const getPriceVariantsDisplay = ({ preset }: { preset: Preset }) => {
  return [Preset.Center, Preset.ExpandCenter].includes(preset) ? 'flex' : 'grid';
};

export const getPriceVariantsJustifyContent = ({ preset }: { preset: Preset }) => {
  return [Preset.Center, Preset.ExpandCenter].includes(preset) ? 'center' : '';
};

export const getPriceVariantsAlignItems = ({ preset }: { preset: Preset }) => {
  return [Preset.Center, Preset.ExpandCenter].includes(preset) ? 'center' : '';
};

export const getPriceVariantsFlexWrap = ({ preset }: { preset: Preset }) => {
  return [Preset.Center, Preset.ExpandCenter].includes(preset) ? 'wrap' : '';
};

export const getPriceVariantsGap = ({ preset }: { preset: Preset }) => {
  return [Preset.Center, Preset.ExpandCenter].includes(preset) ? '0px' : '2px';
};

export const getPriceVariantsMarginBottom = ({ preset }: { preset: Preset }) => {
  return [Preset.Center, Preset.ExpandCenter].includes(preset) ? '0px' : '12px';
};

export const getVariantDisplay = ({ preset }: { preset: Preset }) => {
  return [Preset.Center, Preset.ExpandCenter].includes(preset) ? 'grid' : '';
};

export const getVariantGridTemplate = ({ preset }: { preset: Preset }) => {
  return [Preset.Center, Preset.ExpandCenter].includes(preset)
    ? `[row1-start] ". variant-details . variant-divider" [row1-end]
       / 16px auto 16px auto`
    : '';
};

export const getVariantAlignItems = ({ preset }: { preset: Preset }) => {
  return [Preset.Center, Preset.ExpandCenter].includes(preset) ? 'center' : '';
};

export const getVariantWidth = ({
  preset,
  showDots,
  isMobile,
}: {
  preset: Preset;
  showDots: boolean;
  isMobile: boolean;
}) => {
  return [Preset.Columns, Preset.SideBySide].includes(preset) ||
    (preset === Preset.Grid && showDots) ||
    (preset === Preset.ExpandStrip && !isMobile)
    ? '100%'
    : '';
};

export const getVariantDetailsJustifyContent = ({ preset }: { preset: Preset }) => {
  return [Preset.Columns, Preset.SideBySide, Preset.ExpandStrip].includes(preset) ? 'space-between' : '';
};

export const getVariantDetailsGridArea = ({ preset }: { preset: Preset }) => {
  return [Preset.Center, Preset.ExpandCenter].includes(preset) ? 'variant-details' : '';
};

export const getColumnsImageWidth = ({
  imageWidthEnum,
  isMobile,
}: {
  imageWidthEnum: ImageSizeCategories;
  isMobile: boolean;
}) => {
  const widthMap = isMobile ? ImageSize.MOBILE : ImageSize.DESKTOP;

  return widthMap[imageWidthEnum];
};

export const getMenuTextAlignment = ({
  gridMenuAlignment,
  columnsMenuAlignment,
  sideBySideMenuAlignment,
  expandStripMenuAlignment,
  preset,
}: {
  gridMenuAlignment: Alignment;
  columnsMenuAlignment: Alignment;
  sideBySideMenuAlignment: Alignment;
  expandStripMenuAlignment: Alignment;
  preset: Preset;
}) => {
  const gridAlignmentText = getAlignmentText(gridMenuAlignment);
  const columnsAlignmentText = getAlignmentText(columnsMenuAlignment);
  const sideBySideAlignmentText = getAlignmentText(sideBySideMenuAlignment);
  const expandAlignmentText = getAlignmentText(expandStripMenuAlignment);
  const alignmentMap: Record<Preset, string> = {
    [Preset.Grid]: gridAlignmentText,
    [Preset.Columns]: columnsAlignmentText,
    [Preset.Center]: getAlignmentText(Alignment.Center),
    [Preset.ExpandCenter]: getAlignmentText(Alignment.Center),
    [Preset.SideBySide]: sideBySideAlignmentText,
    [Preset.ExpandStrip]: expandAlignmentText,
  };

  return alignmentMap[preset];
};

export const getSectionTextAlignment = ({
  gridSectionAlignment,
  columnsSectionAlignment,
  sideBySideSectionAlignment,
  expandStripSectionAlignment,
  preset,
}: {
  gridSectionAlignment: Alignment;
  columnsSectionAlignment: Alignment;
  sideBySideSectionAlignment: Alignment;
  expandStripSectionAlignment: Alignment;
  preset: Preset;
}) => {
  const gridAlignmentText = getAlignmentText(gridSectionAlignment);
  const columnsAlignmentText = getAlignmentText(columnsSectionAlignment);
  const sideBySideAlignmentText = getAlignmentText(sideBySideSectionAlignment);
  const expandAlignmentText = getAlignmentText(expandStripSectionAlignment);

  const alignmentMap: Record<Preset, string> = {
    [Preset.Grid]: gridAlignmentText,
    [Preset.Columns]: columnsAlignmentText,
    [Preset.Center]: getAlignmentText(Alignment.Center),
    [Preset.ExpandCenter]: getAlignmentText(Alignment.Center),
    [Preset.SideBySide]: sideBySideAlignmentText,
    [Preset.ExpandStrip]: expandAlignmentText,
  };

  return alignmentMap[preset];
};
export const getTitleContainerDisplay = ({ preset }: { preset: Preset }) => {
  return [Preset.ExpandCenter, Preset.ExpandStrip].includes(preset) ? 'flex' : '';
};
export const getTitleContainerJustifyContent = ({
  preset,
  sectionTextAlignment,
}: {
  preset: Preset;
  sectionTextAlignment: string;
}) => {
  return preset === Preset.ExpandCenter ||
    (preset === Preset.ExpandStrip && sectionTextAlignment === AlignmentText.Center)
    ? 'center'
    : '';
};
export const getTitleContainerFlexDirection = ({
  preset,
  expandStripSectionAlignment,
}: {
  preset: Preset;
  expandStripSectionAlignment: Alignment;
}) => {
  return preset === Preset.ExpandStrip && expandStripSectionAlignment === Alignment.End ? 'row-reverse' : '';
};
export const getSectionTitleFlexBasis = ({
  preset,
  sectionTextAlignment,
}: {
  preset: Preset;
  sectionTextAlignment: string;
}) => {
  return preset === Preset.ExpandCenter ||
    (preset === Preset.ExpandStrip && sectionTextAlignment === AlignmentText.Center)
    ? 'content'
    : '';
};
export const getTitleContainerPointerEvents = ({ preset }: { preset: Preset }) => {
  return preset === Preset.ExpandCenter || preset === Preset.ExpandStrip ? 'auto' : 'none';
};
export const getItemTextAlignment = ({
  gridItemAlignment,
  preset,
}: {
  gridItemAlignment: Alignment;
  preset: Preset;
}) => {
  const gridAlignmentText = getAlignmentText(gridItemAlignment);

  const alignmentMap: Record<Preset, AlignmentText> = {
    [Preset.Grid]: gridAlignmentText,
    [Preset.Columns]: getAlignmentText(Alignment.Start),
    [Preset.Center]: getAlignmentText(Alignment.Center),
    [Preset.ExpandCenter]: getAlignmentText(Alignment.Center),
    [Preset.SideBySide]: getAlignmentText(Alignment.Start),
    [Preset.ExpandStrip]: getAlignmentText(Alignment.Start),
  };

  return alignmentMap[preset];
};

export const convertAlignmentToJustifyContent = ({ alignment }: { alignment: AlignmentText }) => {
  switch (alignment) {
    case AlignmentText.Start:
      return 'flex-start';
    case AlignmentText.Center:
      return 'center';
    case AlignmentText.End:
      return 'flex-end';
  }
};

export const getNavigationTextAlignment = ({
  gridMenuNavigationAlignment,
  columnsMenuNavigationAlignment,
  sideBySideMenuNavigationAlignment,
  expandStripMenuNavigationAlignment,
  preset,
  isRTL,
  isRow,
  isMobile,
}: {
  gridMenuNavigationAlignment: Alignment;
  columnsMenuNavigationAlignment: Alignment;
  sideBySideMenuNavigationAlignment: Alignment;
  expandStripMenuNavigationAlignment: Alignment;
  preset: Preset;
  isRTL: boolean;
  isRow?: boolean;
  isMobile?: boolean;
}) => {
  if (isRow && !isMobile) {
    return getAlignmentByLanguage(AlignmentText.End, isRTL);
  }

  const gridAlignmentText = getAlignmentText(gridMenuNavigationAlignment);
  const columnsAlignmentText = getAlignmentText(columnsMenuNavigationAlignment);
  const sideBySideAlignmentText = getAlignmentText(sideBySideMenuNavigationAlignment);
  const expandAlignmentText = getAlignmentText(expandStripMenuNavigationAlignment);
  const alignmentMap: Record<Preset, string> = {
    [Preset.Grid]: getAlignmentByLanguage(gridAlignmentText, isRTL),
    [Preset.Columns]: getAlignmentByLanguage(columnsAlignmentText, isRTL),
    [Preset.Center]: HeaderAlignment.Center,
    [Preset.ExpandCenter]: HeaderAlignment.Center,
    [Preset.SideBySide]: getAlignmentByLanguage(sideBySideAlignmentText, isRTL),
    [Preset.ExpandStrip]: getAlignmentByLanguage(expandAlignmentText, isRTL),
  };

  return alignmentMap[preset];
};

export const showPriceVariantsDivider = ({
  preset,
  showItemVariantsDivider,
}: {
  preset: Preset;
  showItemVariantsDivider: boolean;
}) => {
  return hasPriceVariantDividers(preset) && showItemVariantsDivider ? 'block' : 'none';
};

export const showMenuSideImage = ({
  preset,
  isMobile,
  showMenusSideImage,
}: {
  preset: Preset;
  isMobile: boolean;
  showMenusSideImage: boolean;
}) => {
  return preset === Preset.SideBySide && (!isMobile || showMenusSideImage) ? 'block' : 'none';
};

export const getElementDisplay = ({
  shouldShow,
  displayOption = 'block',
}: {
  shouldShow: boolean;
  displayOption?: string;
}) => {
  return shouldShow ? displayOption : 'none';
};

export const getHeaderAlignItems = ({
  gridHeaderAlignItems,
  columnsHeaderAlignItems,
  sideBySideHeaderAlignItems,
  centerHeaderAlignItems,
  expandStripHeaderAlignItems,
  expandCenterHeaderAlignItems,
  preset,
  isMobile,
}: {
  gridHeaderAlignItems: Alignment;
  columnsHeaderAlignItems: Alignment;
  sideBySideHeaderAlignItems: Alignment;
  centerHeaderAlignItems: Alignment;
  expandStripHeaderAlignItems: Alignment;
  expandCenterHeaderAlignItems: Alignment;
  preset: Preset;
  isMobile: boolean;
}) => {
  if (isMobile) {
    return AlignmentText.Center;
  }

  const alignmentMap: Record<Preset, AlignmentText> = {
    [Preset.Grid]: getAlignmentText(gridHeaderAlignItems),
    [Preset.Columns]: getAlignmentText(columnsHeaderAlignItems),
    [Preset.Center]: getAlignmentText(centerHeaderAlignItems),
    [Preset.ExpandCenter]: getAlignmentText(expandCenterHeaderAlignItems),
    [Preset.SideBySide]: getAlignmentText(sideBySideHeaderAlignItems),
    [Preset.ExpandStrip]: getAlignmentText(expandStripHeaderAlignItems),
  };

  return convertAlignmentToJustifyContent({ alignment: alignmentMap[preset] });
};

export const getHeaderFlexDirection = ({
  gridHeaderFlexDirection,
  columnsHeaderFlexDirection,
  sideBySideHeaderFlexDirection,
  centerHeaderFlexDirection,
  expandStripHeaderFlexDirection,
  expandCenterHeaderFlexDirection,
  preset,
  isMobile,
}: {
  gridHeaderFlexDirection: Direction;
  columnsHeaderFlexDirection: Direction;
  sideBySideHeaderFlexDirection: Direction;
  centerHeaderFlexDirection: Direction;
  expandStripHeaderFlexDirection: Direction;
  expandCenterHeaderFlexDirection: Direction;
  preset: Preset;
  isMobile: boolean;
}) => {
  if (isMobile) {
    return DirectionText.Column;
  }

  const displayMap: Record<Preset, string> = {
    [Preset.Grid]: getDirectionText(gridHeaderFlexDirection),
    [Preset.Columns]: getDirectionText(columnsHeaderFlexDirection),
    [Preset.Center]: getDirectionText(centerHeaderFlexDirection),
    [Preset.ExpandCenter]: getDirectionText(expandCenterHeaderFlexDirection),
    [Preset.SideBySide]: getDirectionText(sideBySideHeaderFlexDirection),
    [Preset.ExpandStrip]: getDirectionText(expandStripHeaderFlexDirection),
  };

  return displayMap[preset];
};

export const getHeaderContainerGridArea = ({ preset }: { preset: Preset }) => {
  return preset === Preset.SideBySide ? 'header' : '';
};

export const getDotSvgUrl = ({ size }: { size: number }) => {
  const width = size * 4;
  const cx = size * 2;
  const cy = size / 2;

  return `url("data:image/svg+xml,%3Csvg width='${width}' height='${size}' viewBox='0 0 ${width} ${size}' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='${cx}' cy='${cy}' r='${cy}' fill='black'/%3E%3C/svg%3E")`;
};

export const getNameAndPriceAlignSelf = ({ showDots }: { showDots: boolean }) => {
  return showDots ? 'end' : '';
};

const fontSizeToNumber = (size?: number | string) => {
  if (size) {
    if (typeof size === 'number') {
      return size;
    }
    const numbers = size.match(/\d+/g);

    if (numbers) {
      return Number(numbers[0]);
    }
  }
};

export const getDotsMargin = ({ nameFont }: { nameFont?: { size?: number | string } }) => {
  const fontSize = fontSizeToNumber(nameFont?.size) ?? 16;

  return `${fontSize / 4}px`;
};

const getJustifyContentForDirection = (direction: Direction): string => {
  return direction === Direction.Horizontal ? JustifyContentText.SpaceBetween : JustifyContentText.None;
};

export const getHeaderJustifyContent = ({
  gridFlexDirection,
  columnsFlexDirection,
  sideBySideFlexDirection,
  centerFlexDirection,
  expandStripFlexDirection,
  expandCenterFlexDirection,
  preset,
  isMobile,
}: {
  gridFlexDirection: Direction;
  columnsFlexDirection: Direction;
  sideBySideFlexDirection: Direction;
  centerFlexDirection: Direction;
  expandStripFlexDirection: Direction;
  expandCenterFlexDirection: Direction;
  preset: Preset;
  isMobile: boolean;
}) => {
  if (isMobile) {
    return getJustifyContentForDirection(Direction.Vertical);
  }

  const directionMap: Record<Preset, Direction> = {
    [Preset.Grid]: gridFlexDirection,
    [Preset.Columns]: columnsFlexDirection,
    [Preset.Center]: centerFlexDirection,
    [Preset.SideBySide]: sideBySideFlexDirection,
    [Preset.ExpandStrip]: expandStripFlexDirection,
    [Preset.ExpandCenter]: expandCenterFlexDirection,
  };

  return getJustifyContentForDirection(directionMap[preset]);
};

export const getRowHeaderDirection = ({
  gridHeaderFlexDirection,
  columnsHeaderFlexDirection,
  sideBySideHeaderFlexDirection,
  centerHeaderFlexDirection,
  expandStripHeaderFlexDirection,
  expandCenterHeaderFlexDirection,
  preset,
  isMobile,
}: {
  gridHeaderFlexDirection: Direction;
  columnsHeaderFlexDirection: Direction;
  sideBySideHeaderFlexDirection: Direction;
  centerHeaderFlexDirection: Direction;
  expandStripHeaderFlexDirection: Direction;
  expandCenterHeaderFlexDirection: Direction;
  preset: Preset;
  isMobile: boolean;
}) => {
  if (isMobile) {
    return Direction.Vertical;
  }

  const directionMap: Record<Preset, Direction> = {
    [Preset.Grid]: gridHeaderFlexDirection,
    [Preset.Columns]: columnsHeaderFlexDirection,
    [Preset.Center]: centerHeaderFlexDirection,
    [Preset.ExpandCenter]: expandCenterHeaderFlexDirection,
    [Preset.SideBySide]: sideBySideHeaderFlexDirection,
    [Preset.ExpandStrip]: expandStripHeaderFlexDirection,
  };
  return directionMap[preset];
};
