import React from 'react';
import type { ShowcasePopulatedItem } from 'root/apiTypes';
import { Item } from 'root/components/Menus/Item/Item';
import { classes, st } from './Items.st.css';
import { dataHooks } from 'root/dataHooks';
import { ItemsContextProvider } from 'root/contexts/ItemsContext';
import { Divider } from 'wix-ui-tpa/cssVars';
import { useItemsLogic } from './Items.logic';

export const Items = ({
  isSectionCollapsed = false,
  items,
  sectionId,
  sectionTitleId,
}: {
  items: ShowcasePopulatedItem[];
  sectionId: string;
  sectionTitleId: string;
  isSectionCollapsed?: boolean;
}) => {
  const { isExpandCollapsePresetExperimentEnabled } = useItemsLogic();
  return (
    <ItemsContextProvider value={{ sectionId }}>
      <div
        aria-labelledby={sectionTitleId}
        data-hook={dataHooks.items.container}
        role="list"
        className={st(
          classes.itemsContainer,
          isSectionCollapsed && isExpandCollapsePresetExperimentEnabled ? classes.collapse : ''
        )}
      >
        {items.map((item) => (
          <>
            <div className={classes.dividerContainer}>
              <Divider className={classes.divider} data-hook={dataHooks.items.divider} />
            </div>
            <Item key={item.id} item={item} />
          </>
        ))}
      </div>
    </ItemsContextProvider>
  );
};
